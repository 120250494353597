"use client";

import {
    Box,
    Stack,
    Text,
    Image,
    Flex,
    VStack,
    Button,
    Heading,
    SimpleGrid,
    StackDivider,
    useColorModeValue,
    List,
    ListItem,
    Link,
    ChakraProvider,
    Tag,
    Table
} from "@chakra-ui/react";
import { useRef } from "react";
import GridListWith from "./team-pages-components/multiCardTemplate";
import avatar from "../../components/data/img/maria.jpg";
import pic1 from "../../components/data/img/teampage/MZ_Picture1.jpg";
import pic2 from "../../components/data/img/teampage/MZ_Picture2.png";
import pic3 from "../../components/data/img/teampage/MZ_Picture3.jpg";
import pic4 from "../../components/data/img/teampage/MZ_Picture4.jpg";


export default function MariaTeamPage() {
    // Refs for scrolling
    const sectionRef = useRef(null);
    const projectDetailsRef = useRef(null);
    const otherDetailsRef = useRef(null);
    const bioRef = useRef(null);

    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    return (
        <ChakraProvider>
            <Flex h="100vh" px={10}>
                {/* Left Column */}
                <Box
                    w={{ base: "100%", lg: "30%" }}
                    position={{ base: "static", lg: "sticky" }}
                    top={0}
                    h="100vh"
                    overflow="hidden"
                    p={4}

                >
                    <Stack spacing={6}>
                        <Flex>
                            <Image
                                rounded={"md"}
                                alt={"product image"}
                                src={avatar}
                                fit={"contain"}
                                align={"center"}
                                w={"100%"}
                                h={{ base: "100%", sm: "300px", lg: "400px" }}
                            />
                        </Flex>
                        <VStack align="start" spacing={4} ml={20}>
                            <Button
                                onClick={() => scrollToSection(bioRef)}
                                colorScheme="blue"
                                variant="link"
                            >
                                About
                            </Button>
                            {/* <Button
                onClick={() => scrollToSection(sectionRef)}
                colorScheme="blue"
                variant="link"
              >
                Section
              </Button> */}
                            <Button
                                onClick={() => scrollToSection(projectDetailsRef)}
                                colorScheme="blue"
                                variant="link"
                            >
                                Projects
                            </Button>
                            {/* <Button
                                onClick={() => scrollToSection(otherDetailsRef)}
                                colorScheme="blue"
                                variant="link"
                            >
                                Other
                            </Button> */}
                        </VStack>
                    </Stack>
                </Box>

                {/* Right Column */}
                <Box
                    w={{ base: "100%", lg: "70%" }}
                    h="100vh"
                    overflowY="auto"
                    p={4}
                    sx={{
                        "&::-webkit-scrollbar": {
                            width: "0px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "transparent",
                        },
                        "&:hover::-webkit-scrollbar": {
                            width: "8px",
                        },
                        "&:hover::-webkit-scrollbar-thumb": {
                            background: "orange.200",
                            borderRadius: "4px",
                        },
                    }}
                >
                    <Stack spacing={6}>
                        <Box as={"header"} ref={bioRef}>
                            <Heading
                                lineHeight={1.1}
                                fontWeight={600}
                                fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                            >
                                Maria Zubkova
                            </Heading>
                            <Text
                                color={useColorModeValue("gray.900", "gray.400")}
                                fontWeight={300}
                                fontSize={"2xl"}
                                mb={6}
                            >
                                Assistant Research Professor
                            </Text>
                            <Text
                                fontSize={{ base: "16px", lg: "18px" }}
                                color={useColorModeValue("yellow.500", "yellow.300")}
                                fontWeight={"500"}
                                textTransform={"uppercase"}

                            >
                                About
                            </Text>
                        </Box>

                        <Stack
                            spacing={6}
                            direction={"column"}
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue("gray.200", "gray.600")}
                                />
                            }
                        >
                            <VStack spacing={6}>
                                <Text

                                    fontSize={"lg"}
                                //   fontWeight={"300"}
                                >
                                    I am an Assistant Research Professor at the Department of Geographical Sciences at the University of Maryland, College Park. My research focuses on mapping various aspects of fire activity based on remote-sensed data and improving our understanding of the complex interaction between wildfires, climate, biodiversity, and humans across scales. The goal of my most recent research is to promote the use of beneficial fires and enhance ecosystem resilience to fire since, in recent years, catastrophic fires have led to the loss of billions of dollars, lives, and undesirable ecological and environmental consequences.
                                    I received my PhD in Natural Resources with an emphasis on Remote Sensing and Geographical Information Sciences from the University of Idaho, my MS in Geoinformation in Environmental Management from the Mediterranean Agronomic Institute of Chania (MAICh) in Greece, and a BA+MS in Landscape Design from the Russian State Agrarian University, Moscow, Russia.
                                </Text>
                                <Text fontSize={"lg"}>

                                    I am a Board member of the Society for Conservation GIS <Link color="blue.500" href='https://www.scgis.org/'>SCGIS</Link> and the Chair of the SCGIS conference committee. In 2022, I was awarded the Outstanding Postdoctoral Associate in the Department of Geographical Sciences, and 2 years later, in 2024, I was awarded the Outstanding Research Faculty in the Department of Geographical Sciences.

                                </Text>
                            </VStack>

                            {/* Section */}
                            {/* <Box ref={sectionRef}>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={useColorModeValue("yellow.500", "yellow.300")}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={4}
                >
                  Section
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                  <List spacing={2}>
                    <ListItem>Lorem ipsum</ListItem>
                    <ListItem>Odor amet</ListItem>
                    <ListItem>Consectetuer</ListItem>
                  </List>
                  <List spacing={2}>
                    <ListItem>Adipiscing</ListItem>
                    <ListItem>Elit</ListItem>
                    <ListItem>Nunc aenean</ListItem>
                  </List>
                </SimpleGrid>
              </Box> */}

                            {/* Projects Section */}
                            <Box ref={projectDetailsRef}>
                                <Text
                                    fontSize={{ base: "16px", lg: "18px" }}
                                    color={useColorModeValue("yellow.500", "yellow.300")}
                                    fontWeight={"500"}
                                    textTransform={"uppercase"}
                                    mb={4}
                                >
                                    Projects
                                </Text>
                                <SimpleGrid columns={2} spacing={6}>
                                    <VStack>
                                        <Image
                                            rounded={"md"}
                                            alt={"Manyeleti Nature Reserve in South Africa "}
                                            src={pic4}
                                            fit={"contain"}
                                            align={"left"}
                                            w={"100%"}
                                            h={{ base: "100%", sm: "300px", lg: "400px" }}
                                        />
                                        <Text
                                            color={useColorModeValue("gray.500", "gray.400")}
                                            as='cite'
                                        > Manyeleti Nature Reserve in South Africa (photo credit: Frik Bronkhorst)</Text>
                                    </VStack>
                                    <VStack>
                                        <Text>
                                            In areas where fire is a dominant ecological disturbance and vital habitat management technique, the long-term fire suppression led to the diminishment of habitats and the buildup of unburned fuels that risk larger, more intense, and harder-to-control wildfires. Specifically, prescribed fires are commonly used in tropical savannas and the southeastern U.S. to reduce fuels, enhance wildlife habitat, favor endangered and threatened species, and restore vegetation structure. Despite widespread application and a number of benefits of those controlled burns, their use remains controversial.
                                            The near absence of prescribed fire statistics due to difficulties in mapping those small
                                            low-intensity burns precludes the evaluation of the effectiveness of extensive prescribed fire
                                            management for fuel and fire risk reduction, which, if present, would encourage
                                            burning and educate the public about the benefits of prescribed fires.

                                        </Text>
                                        <Text>
                                            The study titled “Woody Encroachment in Sub-Saharan Africa: The Cause or the Effect of the Decline in Fire Activity?” funded by NASA Early Career Investigator Program in Earth Science, aims to study the relationships between fire management and woody encroachment in African savannas. The outcomes of this project are essential for guiding climate change adaptation, biodiversity and ecosystem preservation, risk mitigation, poverty alleviation, and community resilience in this ecologically and economically important biome. Specifically, the project results will inform the debate about whether local management has leverage in controlling woody encroachment in the face of global change. The referenced data produced in collaboration with biodiversity planers from several protected areas in South Africa guided prescribed fires during the 2023 and 2024 seasons <Link color="blue.500" href='https://doi.org/10.1016/j.jag.2024.104064'> (Zubkova et al., 2024) </Link>.  Those fires aimed to preserve the woody-grass equilibrium essential for biodiversity within those natural reserves.

                                        </Text>
                                    </VStack>
                                    <VStack>
                                        <Image
                                            rounded={"md"}
                                            alt={"Manyeleti Nature Reserve in South Africa "}
                                            src={pic1}
                                            fit={"contain"}
                                            align={"left"}
                                            w={"100%"}
                                            h={{ base: "100%", sm: "300px", lg: "400px" }}
                                        />
                                        <Text
                                            color={useColorModeValue("gray.500", "gray.400")}
                                            as='cite'
                                        > Manyeleti Nature Reserve in South Africa (photo credit: Mervyn Lötter)</Text>
                                    </VStack>
                                    <VStack>
                                        <Text>
                                            Another NASA-funded research that I lead is titled “A decision support system for automated precise prescribed fire mapping in the Everglades Headwaters”. The project funded by the Earth Action: Wildland Fires program will automate prescribed fire mapping in Central Florida through the multi-sensor fusion of NASA, commercial satellite, and drone imagery. Prescribed fires are typically small and low-intensity and, therefore, remain undetected by coarse resolution datasets, the only currently available ready-to-use fire data. I believe that providing accurate data on prescribed fires can encourage burning and educate the public about their benefits, such as the reduction of the buildup of unburned fuels that risk larger, more intense, and harder-to-control wildfires, enhance wildlife habitat, favor endangered and threatened species, and restore vegetation structure. Therefore, the data that will be developed in collaboration with <Link color="blue.500" href="https://www.archbold-station.org/">Archbold Biological Station</Link> will be used as a foundation to make better recommendations for management in rare and endemic bird species protection and expand our understanding of fire management in rapidly developing areas.

                                        </Text>


                                    </VStack>
                                    <VStack>
                                        <Image
                                            rounded={"md"}
                                            alt={"Manyeleti Nature Reserve in South Africa "}
                                            src={pic2}
                                            fit={"contain"}
                                            align={"left"}
                                            w={"100%"}
                                            h={{ base: "100%", sm: "300px", lg: "400px" }}
                                        />
                                        <Text
                                            color={useColorModeValue("gray.500", "gray.400")}
                                            as='cite'
                                        > A time series (2002–2020) of the annual burned area accumulated over a calendar year within all 14 governorates of Syria. </Text>
                                    </VStack>
                                        <Text>
                                            Apart from developing methods for mapping prescribed fires and assessing their benefits for biodiversity conservation, I focus my research on monitoring abrupt changes in wildfires in developing countries that do not get enough attention from journalists or scientists but where changes in fire behavior can be as detrimental or even more compared to countries with higher fire management and suppression budgets. My past work explored shifts in fire activity as the result of violent conflicts and massive human migration in Syria <Link color="blue.500" href="https://doi.org/10.1175/EI-D-21-0009.1">(Zubkova et al., 2021) </Link> and South Sudan – North Uganda <Link color="blue.500" href="https://doi.org/10.1088/1748-9326/ac6e48">(Nakalembe et al., 2022)</Link>, which is currently a grossly underrepresented topic of fire research. Data gathering is limited in unsafe conflict zones. Remote sensed data can provide unbiased statistics of fire counts and land cover changes that can facilitate policy decisions for humanitarian assistance, distributing targeted aid, or inclusive development programs. Specifically, in Syria, based on NASA MODIS Burned Area Product (MCD64A1), a 2300% increase in fire activity was detected in 2019 compared to 2002-2018 conditions. A year later, in 2020, while fire activity was reduced relative to 2019, it still reached an astonishing amount, 10 times above the average.
                                        </Text>
                                        <VStack>
                                        <Image
                                            rounded={"md"}
                                            alt={"Manyeleti Nature Reserve in South Africa "}
                                            src={pic3}
                                            fit={"contain"}
                                            align={"left"}
                                            w={"100%"}
                                            h={{ base: "100%", sm: "300px", lg: "400px" }}
                                        />
                                        <Text
                                            color={useColorModeValue("gray.500", "gray.400")}
                                            as='cite'
                                        > 
                                        Fire Activity in Syria
                                        </Text>
                                    </VStack>
                                        <Text>
                                        Abnormally high rainfall during winter-spring 2019 explained close to two-thirds of the drastic increase in the extent of the burned area in the northern part of Syria. In 2020, fire activity moved to the west, away from wheat and barley’s major production, to government- or opposition-controlled territory. Above-normal temperatures were recorded at the end of the 2020 fire season, especially in the northwest part of the country, which was partially responsible for the increase in fire activity in more biomass-rich ecosystems. Heavy rainfall events, while rare, were observed in the previous year, but Syria remained a nearly fire-free country before the summer of 2019. This study confirmed that a drastic change in fire activity is usually a result of a combination of climatic and anthropogenic factors, in this case, fire-favorable weather conditions, poor emergency response due to the war, and maliciously started fires by the Islamic State, pro-government militias, or opposition rebels created an abundance of highly combustible fuel together with a plenitude of ignition sources.                                         </Text>
                                </SimpleGrid>


                            </Box>
                            {/* <Box ref={otherDetailsRef}>
                                <Text
                                    fontSize={{ base: "16px", lg: "18px" }}
                                    color={useColorModeValue("yellow.500", "yellow.300")}
                                    fontWeight={"500"}
                                    textTransform={"uppercase"}
                                    mb={4}
                                >
                                    Other
                                </Text>
                                <GridListWith />
                            </Box> */}
                        </Stack>
                    </Stack>
                </Box>
            </Flex>
        </ChakraProvider>
    );
}
