"use client";

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  Image
} from "@chakra-ui/react";
import {
  FcAbout,
  FcConferenceCall,
  FcCollaboration,
  FcDocument,
  FcOpenedFolder,
} from "react-icons/fc";

const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={useColorModeValue("gray.100", "gray.700")}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
        <Button variant={"link"} colorScheme={"blue"} size={"sm"}>
          Learn more
        </Button>
      </Stack>
    </Box>
  );
};

const GridListWith = () => {
  return (
    <Box>
      {/* <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={{ base: "2xl", sm: "4xl" }} fontWeight={"bold"}>
          heading 2
        </Heading>
        <Text color={"gray.600"} fontSize={{ base: "sm", sm: "lg" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis
          obcaecati ut cupiditate pariatur, dignissimos, placeat amet officiis.
        </Text>
      </Stack> */}

      <Container maxW={"5xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={"Card 1"}
            icon={<Icon as={FcConferenceCall} w={10} h={10} />}
            
            description={"Lorem ipsum dolor sit amet consectetur adipisicing elit."}
            href={"#"}
          >
            </Card>
          <Card
            heading={"Card 2"}
            icon={<Icon as={FcCollaboration} w={10} h={10} />}
            description={"Lorem ipsum dolor sit amet consectetur adipisicing elit."}
            href={"#"}
          />
          <Card
            heading={"Card 3"}
            icon={<Icon as={FcDocument} w={10} h={10} />}
            description={"Lorem ipsum dolor sit amet consectetur adipisicing elit."}
            href={"#"}
          />
          <Card
            heading={"Card 4"}
            icon={<Icon as={FcOpenedFolder} w={10} h={10} />}
            description={"Lorem ipsum dolor sit amet consectetur adipisicing elit."}
            href={"#"}
          />
          <Card
            heading={"Card 5"}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={"Lorem ipsum dolor sit amet consectetur adipisicing elit."}
            href={"#"}
          />
        </Flex>
      </Container>
    </Box>
  );
};

export default GridListWith;
