import React from "react"
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  VStack,
  ChakraProvider,
  theme
} from "@chakra-ui/react"

import giglio from "./data/img/giglio.jpg"
import viirsv002 from "./data/img/viirsv002.png"
import mzNASAGrant from "./data/img/mz_nasa_grant.png"
import zubkova from "./data/img/maria.jpg"
import hall from "./data/img/joanne.jpg"
import roy from "./data/img/roy.jpg"
import humber from "./data/img/mike.jpg"

const BlogTags = props => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map(tag => {
        return (
          <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        )
      })}
    </HStack>
  )
}

const authorImages = {
  'L. Giglio': giglio,
  'M. Zubkova': zubkova,
  'J. V. Hall': hall,
  'D. Roy': roy,
  'M. Humber': humber,

  // add more mappings here
};

export const BlogAuthor = props => {
  const authorImage = authorImages[props.name] || giglio;
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        //boxSize="50px"
        height="45px"
        width="35px"
        src={authorImage}
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  )
}


const LandingNews = () => {
  return (
    <ChakraProvider theme={theme}>
    <Container maxW={"7xl"} p="12">
      <Heading as="h1">Latest News</Heading>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
        >
          <Box
            width={{ base: "100%", sm: "85%" }}
            zIndex="2"
            marginLeft={{ base: "0", sm: "5%" }}
            marginTop="5%"
          >
            <Link href={"https://geog.umd.edu/featured-content/nasa-grant-support-prescribed-fire-mapping-everglades"} textDecoration="none" _hover={{ textDecoration: "none" }}>
              <Image
                borderRadius="xs"
                src={mzNASAGrant}
                alt="prescribed fire mapping"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                "radial(orange.600 1px, transparent 1px)",
                "radial(orange.300 1px, transparent 1px)"
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: "3", sm: "0" }}
        >
          <BlogTags tags={["Prescribed","Fire Mapping", "Everglades"]} />
          <Heading marginTop="1">
            <Link href={"https://geog.umd.edu/featured-content/nasa-grant-support-prescribed-fire-mapping-everglades"} textDecoration="none" _hover={{ textDecoration: "none" }}>
            NASA Grant Awarded to Support Prescribed Fire Mapping in the Everglades
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue("gray.700", "gray.200")}
            fontSize="lg"
          >
            Assistant Research Professor Maria Zubkova has received NASA funding to develop a decision support system that uses satellite data to 
            automate the mapping of prescribed fires in Florida’s Everglades Headwaters. 
            This tool will provide more precise and comprehensive data on burn size, seasonality, 
            and frequency, supporting biodiversity conservation, improved habitat management, 
            and reduced wildfire risk. By streamlining data collection, 
            it aims to enhance resource allocation and bolster community resilience 
            in fire-adapted ecosystems.
          </Text>
          <BlogAuthor name="M. Zubkova" date={new Date("2024-11-11T19:01:27Z")} />
        </Box>
        
      </Box>
      <Divider marginTop="5" />
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
        >
          <Box
            width={{ base: "100%", sm: "85%" }}
            zIndex="2"
            marginLeft={{ base: "0", sm: "5%" }}
            marginTop="5%"
          >
            <Link href={"https://lpdaac.usgs.gov/products/vnp64a1v002/"} textDecoration="none" _hover={{ textDecoration: "none" }}>
              <Image
                borderRadius="xs"
                src={viirsv002}
                alt="VIIR Collection 2"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                "radial(orange.600 1px, transparent 1px)",
                "radial(orange.300 1px, transparent 1px)"
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: "3", sm: "0" }}
        >
          <BlogTags tags={["VIIRS","Version 2 Release", "Burned Area"]} />
          <Heading marginTop="1">
            <Link href={"https://doi.org/10.1073/pnas.2317759120"} textDecoration="none" _hover={{ textDecoration: "none" }}>
            Release of VIIRS Version 2 Burned Area Data Product
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue("gray.700", "gray.200")}
            fontSize="lg"
          >
            Pleased to announce the availability of the Visible Infrared Imaging Radiometer Suite (VIIRS) Version 2 Burned Area data product. The VIIRS Version 2 release includes a data product from the Suomi National Polar-orbiting Partnership (Suomi NPP) satellite. This release covers the mission period from March 2012 up to the leading edge. Details on product improvements for Version 2 are provided on the <Link href="https://doi.org/10.1073/pnas.2317759120" isExternal>Digital Object Identifier (DOI) landing page</Link>.
          </Text>
          <BlogAuthor name="L. Giglio" date={new Date("2024-10-24T19:01:27Z")} />
        </Box>
        
      </Box>
      
      <Divider marginTop="5" />
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h2">Welcome to the Giglio Fire Group Website</Heading>
        <Text as="p" fontSize="lg">Within the Department of Geographical Sciences at the University of Maryland our team has a strong track record in the field of satellite fire mapping, biomass burning research, and global product generation. 
         Our team lead, Dr. Louis Giglio, is the NASA S-NPP and MODIS active fire and burned area Principal Investigator and a member of the NASA MODIS and S-NPP Science Teams. 
          </Text>
          <Text as="p" fontSize="lg"> UMD Wildfire team develops active fire detection and burned area algorithms for a broad suite of space-borne sensors, produces long-term, 
          multi-sensor global fire data sets, validates biomass burning data, and conducts high quality research targeted to improve our understanding of current global fire activity, 
          it’s trends and future projections, together with its impact on human health, biodiversity, and economy.</Text>
        
      </VStack>
    </Container>
    </ChakraProvider>
  )
}

export default LandingNews
