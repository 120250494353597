import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  Input,
  Image,
  Link,
  Heading,
  Text,
  HStack,
  Tag,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "./data/DataTable";
import fireccilt11 from "./data/img/firecci_fig2.png"
import viirsv002 from "./data/img/viirsv002.png"
import mzNASAGrant from "./data/img/mz_nasa_grant.png"

// BlogTags and BlogAuthor Components
const BlogTags = ({ tags }) => (
  <HStack spacing={2} marginTop={2}>
    {tags.map((tag) => (
      <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>
        {tag}
      </Tag>
    ))}
  </HStack>
);

const BlogAuthor = ({ name, date }) => (
  <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
    <Text fontWeight="medium">{name}</Text>
    <Text>—</Text>
    <Text>{date}</Text>
  </HStack>
);

// News Data
const data = [
  {
    image: fireccilt11,
    tags: ["Open Letter"],
    title: "ESA Burned area product artifacts may confound the link between biomass burning and infant mortality",
    description:
      "Open letter to Pullabhotla et al. use of the FireCCILT11 biomass burning exposure data due to known flaws in the product...",
    author: "L. Giglio, D. Roy",
    date: "2023-12-07",
    link: "https://doi.org/10.1073/pnas.2317759120",
  },
  {
    image: viirsv002,
    tags: ["VIIRS","Version 2 Release", "Burned Area"],
    title: "LP DAAC Releases VIIRS Version 2 Burned Area Data Product",
    description:
      "The LP DAAC is pleased to announce the availability of the Visible Infrared Imaging Radiometer Suite (VIIRS) Version 2 Burned Area data product. The VIIRS Version 2 release includes a data product from the Suomi National Polar-orbiting Partnership (Suomi NPP) satellite. This release covers the mission period from March 2012 up to the leading edge. Details on product improvements for Version 2 are provided on the Digital Object Identifier (DOI) landing page.",
    author: "L. Giglio",
    date: "2024-10-23",
    link: "https://lpdaac.usgs.gov/products/vnp64a1v002/",


  },
  {
    image: mzNASAGrant,
    tags: ["NASA Grant","Prescribed","Fire Mapping", "Everglades"],
    title: "NASA Grant Awarded to Develop Prescribed Fire Mapping in the Everglades",
    description:
      "Assistant Research Professor Maria Zubkova has received NASA funding to develop a decision support system that uses satellite data to automate the mapping of prescribed fires in Florida’s Everglades Headwaters...",
    author: "M. Zubkova",
    date: "2024-11-11",
    link: "https://geog.umd.edu/featured-content/nasa-grant-support-prescribed-fire-mapping-everglades",
  }
];

// Column Helper
const columnHelper = createColumnHelper();

// Columns Configuration
const columns = [
  columnHelper.accessor("date", {
    cell: (info) => {
      const formattedDate = new Date(info.getValue()).toISOString().slice(0, 7);
      return formattedDate;
    },
    header: "Date",
  }),
  columnHelper.accessor("author", {
    cell: (info) => info.getValue(),
    header: "Personnel",
  }),
  columnHelper.accessor("image", {
    cell: (info) => (
      <Box
        position="relative"
        padding="25px"
        backgroundColor="white"
        borderRadius="md"
        overflow="hidden"
      >
        {/* Radial Gradient */}
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="1"
          bgGradient=
            "radial(orange.600 1px, transparent 1px)"
          backgroundSize="20px 20px"
          opacity="0.4"
        />
        {/* Image */}
        <Box position="relative" zIndex="2">
          <Link href={info.row.original.link} textDecoration="none" _hover={{ textDecoration: "none" }}>
            <Image
              borderRadius="xs"
              src={info.getValue()}
              alt={info.row.original.title}
              objectFit="contain"
            />
          </Link>
        </Box>
      </Box>
    ),
    header: "Image",
  }),
  columnHelper.accessor((row) => row.title, {
    id: "details",
    cell: (info) => {
      const row = info.row.original;
      return (
        <Box display="flex" flex="1" flexDirection="column" justifyContent="center" marginTop={{ base: "3", sm: "0" }}>
          <BlogTags tags={row.tags} />
          <Heading marginTop="1">
            <Link href={row.link} textDecoration="none" _hover={{ textDecoration: "none" }}>
              {row.title}
            </Link>
          </Heading>
          <Text as="p" marginTop="2" color="gray.700"fontSize="lg">
            {row.description}
          </Text>
          {/* <BlogAuthor name={row.author} date={row.date} /> */}
        </Box>
      );
    },
    header: "Details",
  }),
];

export default function ArchiveNews() {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter Data Based on Search
  const filteredData = data.filter((row) =>
    Object.values(row)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <ChakraProvider>
      <Box p={6}>
        <Input
          placeholder="Search by title, authors, tags, or date..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          mb={4}
        />
        <DataTable columns={columns} data={filteredData} />
      </Box>
    </ChakraProvider>
  );
}
